import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const envol = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Envol" />
    <h3 className='underline-title'>Envol</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Sur cette terre où les bons principes on cédé<br/>
        Hélas ! à quelles bassesses incommensurables!<br/>
        L'esprit cherche au-delà de la voûte étoilée<br/>
        Un vaisseau sidéral et des voies navigables<br/>
      </p>
      <p>
        L'esprit tranquille nage en onde éthérée<br/>
        Très haut il regarde la terre survoltée:<br/>
        Volant cassant tuant s'entre-tuant<br/>
        Avide de tout même de vermine avilissante!<br/>
      </p>
      <p>
        Survolant lest envies morbides des terriens<br/>
        L'esprit joyeux sillonne la strate non toxique <br/>
        Quelle source d'énergies profondes!<br/>
        Fontaine inaccessible au machiavélique<br/>
      </p>
      <p>
        Dommage! l'idéal vaisseau n'est pas commun<br/>
        Impossible au commun des mortels<br/>
        Il faut être Youri GAGARINE<br/>
        Mystagogue d'engins spaciaux<br/>
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default envol
